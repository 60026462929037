<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'aulas.complementares' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Lançando Frequências
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="aula">
        <v-card color="green lighten-1" class="mb-5" outlined>
          <v-card-title> Professor: {{ aula.professor.nome }} </v-card-title>
          <v-card-subtitle class="font-weight-medium">
            <div v-if="aula.disciplina">Disciplina: {{ aula.disciplina?.descricao }}</div>
            <div v-else>
              <div v-for="(item, index) in aula.disciplinas" :key="index">
                <v-chip x-small>{{ getNomeDisciplina(item) }} </v-chip>
              </div>
            </div>
            <div>Data: {{ aula.data_formatada }}</div>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="aula">
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Aluno</th>
                <th class="text-left">Presença</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="presenca of presencas"
                :key="presenca.aluno_id"
                :class="
                  $constants.situacoesMatriculaCondition[
                    removerEspaco(presenca.aluno.situacao ? presenca.aluno.situacao : '')
                  ]
                    ? 'red lighten-4'
                    : null
                "
              >
                <td class="font-weight-medium">
                  <div>
                    {{
                      presenca.aluno
                        ? presenca.aluno.nomecompleto
                        : `[ALUNO POSSIVELMENTE DELETADO] ALUNO: ${presenca.aluno.id}`
                    }}
                    <v-chip
                      label
                      color="error"
                      small
                      v-if="
                        $constants.situacoesMatriculaCondition[
                          removerEspaco(presenca.aluno.situacao ? presenca.aluno.situacao : '')
                        ]
                      "
                      >{{ presenca.aluno.situacao }}</v-chip
                    >
                  </div>
                  <div>
                    <small>
                      {{ presenca.aluno ? presenca.aluno.codigo_inep : "- - -" }}
                      /
                      {{ presenca.aluno ? presenca.aluno.codigo : "- - -" }}
                    </small>
                  </div>
                </td>
                <td>
                  <v-btn-toggle :value="presenca.presente ? 0 : 1" v-if="presenca.aluno">
                    <v-btn
                      v-if="
                        !$constants.situacoesMatriculaCondition[
                          removerEspaco(presenca.aluno.situacao ? presenca.aluno.situacao : '')
                        ]
                      "
                      @click="atualizarFrequencia(presenca, true)"
                      :color="presenca.presente ? 'green' : 'grey lighten-2'"
                      small
                      ><v-icon x-small>fa fa-thumbs-up</v-icon></v-btn
                    >
                    <v-btn
                      v-if="
                        !$constants.situacoesMatriculaCondition[
                          removerEspaco(presenca.aluno.situacao ? presenca.aluno.situacao : '')
                        ]
                      "
                      @click="atualizarFrequencia(presenca, false)"
                      :color="!presenca.presente ? 'red' : 'grey lighten-2'"
                      small
                      ><v-icon x-small>fa fa-thumbs-down</v-icon></v-btn
                    >
                  </v-btn-toggle>
                </td>
                <td>
                  <v-btn
                    v-if="!presenca.presente"
                    color="success"
                    @click="modalFalta(presenca.aluno, presenca.justificativa_id)"
                    small
                    type="button"
                  >
                    Observação
                  </v-btn>
                  <v-tooltip v-if="!presenca.presente && presenca.document" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        @click="documentoJustificativaFalta(presenca.aluno.id)"
                        class="ml-2"
                        color="red lighten-5"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="red lighten-1" small> fa fa-file-pdf</v-icon>
                      </v-btn>
                    </template>
                    <span>Baixar documento</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-snackbar
      dense
      :value="exibirSnackbar"
      :timeout="-1"
      class="mb-12"
      :color="snackbarColor"
      right
      bottom
    >
      <div v-if="promisesCount > 0" class="d-flex align-center">
        <v-progress-circular indeterminate size="25" color="white"></v-progress-circular>
        <span class="ml-2 font-weight-medium">Salvando dados de frequência no servidor</span>
        <v-btn @click="fecharSnackbar" class="ml-2" icon><v-icon small>fa fa-close</v-icon></v-btn>
      </div>
      <div v-else-if="errorMessages.length == 0" class="d-flex align-center">
        <v-icon size="20" color="white">fa fa-check</v-icon>
        <span class="ml-2 font-weight-medium">Dados de frequência salvos no servidor!</span>
        <v-btn @click="fecharSnackbar" class="ml-2" icon><v-icon small>fa fa-close</v-icon></v-btn>
      </div>
      <div v-else class="d-flex align-center">
        <v-icon size="20" color="white">fa fa-exclamation-circle</v-icon>
        <span class="ml-2 font-weight-medium">Erro ao salvar dados de frequência!</span>
        <v-btn @click="fecharSnackbar" class="ml-2" icon><v-icon small>fa fa-close</v-icon></v-btn>
      </div>
    </v-snackbar>
    <e-modal-dinamic>
      <ValidationObserver>
        <form :disabled="submittingForm">
          <e-autocomplete
            :items="justificativas"
            :return-object="false"
            :item-value="(value) => value.id"
            :item-text="(value) => value.descricao"
            v-model="form.justificativa_id"
            label="Selecione uma opção"
          />
          <v-file-input v-model="form.file" show-size label="Anexe um arquivo"></v-file-input>
        </form>
      </ValidationObserver>
    </e-modal-dinamic>
  </main-template>
</template>
<script>
export default {
  props: {
    aula_id: {
      type: [String, Number],
      required: true,
    },
  },
  mounted() {
    this.loadBasicData();
    this.getDisciplinas();
  },
  data() {
    return {
      disciplinas: [],
      submittingForm: false,
      aula: null,
      presencas: [],
      exibirSnackbar: false,
      promisesCount: 0,
      errorMessages: [],
      justificativas: [],
      form: {
        observacao: "",
        file: null,
        matriculaSelect: {},
        justificativa_id: 0,
      },
      matriculaSelect: {},
    };
  },
  computed: {
    snackbarColor() {
      if (this.errorMessages.length > 0) return "error";
      return this.promisesCount > 0 ? "info" : "success";
    },
  },
  methods: {
    async getDisciplinas() {
      this.disciplinas = await this.$services.disciplinasService.syncAll();
      // const response = await this.$services.aulasService.disciplinas();
      // this.disciplinas = response.disciplinas;
    },
    getNomeDisciplina(id) {
      const discplina = this.disciplinas.filter((obj) => obj.id === id);
      return discplina[0].descricao;
      // const dis = this.gestaoDeAula.disciplinas.filter((obj) => obj.id === id);
      // return dis[0].descricao;
    },
    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      const response = await this.$services.aulasService.justificativas();
      this.justificativas = response.justificativas;
      const aula = await this.$services.aulasComplementaresService.getAulaComRelacoes(this.aula_id);
      this.aula = aula;
      this.form.file = null;
      this.presencas = aula.gestao_complementar.alunos.map((aluno) => {
        const faltou =
          this.aula.faltas.filter(
            (falta) => parseInt(falta.aluno_id, 10) === parseInt(aluno.id, 10)
          ).length > 0;
        const falta = this.aula.faltas.filter(
          (falta) => parseInt(falta.aluno_id, 10) === parseInt(aluno.id, 10)
        );
        return {
          aluno,
          aluno_id: aluno.id,
          presente: !faltou,
          document: falta[0] ? falta[0].document : null,
          justificativa_id: falta[0] ? falta[0].justificativa_id : null,
        };
      });
      this.$loaderService.close();
    },
    async atualizarFrequencia(frequencia, novoEstado) {
      frequencia.presente = novoEstado;
      this.exibirSnackbar = true;
      this.promisesCount += 1;
      try {
        await this.$services.aulasComplementaresService.registrarPresenca(this.aula, frequencia);
      } catch (error) {
        this.errorMessages.push(error.message);
        this.$handleError(error);
      }
      this.promisesCount -= 1;
    },
    async modalFalta(matricula, justificativa_id) {
      this.form.matriculaSelect = matricula;
      this.form.justificativa_id = parseInt(justificativa_id, 10);
      this.$modalsDinamic.info({
        title: "Justificar falta",
        label: `Observação`,
        fileinput: "Anexe um arquivo",
        visibleIcon: true,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modalsDinamic.close();
            },
            props: {
              color: "warning",
              small: true,
              text: true,
            },
          },
          {
            text: "Confirmar",
            callback: async () => {
              if (!this.form.justificativa_id) {
                this.$toast.danger("É necessário fornecer uma justificativa para a falta.");
                return;
              }
              try {
                await this.$services.aulasService.justificarFalta(this.aula, this.form);
                this.$modalsDinamic.setOptions({ loading: true });
                this.$modalsDinamic.close();
                this.$toast.success("Arquivo anexado com sucesso!");
                await this.loadBasicData();
              } catch (error) {
                this.$toast.danger("O campo file deve ser um arquivo do tipo: pdf.");
              }
            },
            props: {
              color: "success",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    fecharSnackbar() {
      this.exibirSnackbar = false;
      this.errorMessages = [];
    },
    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
    async documentoJustificativaFalta(matricula_id) {
      this.$loaderService.open("Baixando...");
      try {
        await this.$services.relatoriosService.documentoJustificativaFalta(
          parseInt(this.aula.id, 10),
          parseInt(matricula_id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style scoped></style>
