import Geral from "@/Pages/GestaoDeAulas/Geral.vue";
import GestaoDeAulasIndexPage from "@/Pages/GestaoDeAulas/index.vue";
import NewEditGestaoDeAulaPage from "@/Pages/GestaoDeAulas/NewEditGestaoDeAulaPage.vue";
import NewEditGestaoDeAulaPageGeral from "@/Pages/GestaoDeAulas/NewEditGestaoDeAulaPageGeral.vue";

import MetricasDaTurma from "../Pages/GestaoDeAulas/dashboard.vue";
import MetricasDaTurmaPolivalencia from "../Pages/GestaoDeAulas/dashboardPolivalencia.vue";
import Diario from "../Pages/GestaoDeAulas/Diario.vue";
import PlanoBimestralInfantil from "../Pages/GestaoDeAulas/planoBimestralInfantil.vue";
import PlanosBimestrais from "../Pages/GestaoDeAulas/planosBimestrais.vue";
import PlanoMensalInfantil from "../Pages/GestaoDeAulas/planoMensalInfantil.vue";
import PlanosMensais from "../Pages/GestaoDeAulas/planosMensais.vue";

export default [
  {
    path: "/gestoes-de-aulas/",
    name: "gestoesDeAulas",
    component: GestaoDeAulasIndexPage,
  },
  {
    path: "/gestoes-de-aulas/create",
    name: "gestoesDeAulas.create",
    component: NewEditGestaoDeAulaPage,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas/edit/:gestaoDeAulasId",
    name: "gestoesDeAulas.edit",
    component: NewEditGestaoDeAulaPage,
    props: { editing: true },
  },
  {
    path: "/gestoes-de-aulas/geral",
    name: "gestoesDeAulas.geral",
    component: Geral,
  },
  {
    path: "/gestoes-de-aulas/create",
    name: "gestoesDeAulas.createGeral",
    component: NewEditGestaoDeAulaPageGeral,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas/geral/edit/:gestaoDeAulasId",
    name: "gestoesDeAulas.editGeral",
    component: NewEditGestaoDeAulaPageGeral,
    props: { editing: true },
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulaId/plano-bimestral/:disciplinaId",
    name: "gestoesDeAulas.planoBimestralCreate",
    component: PlanosBimestrais,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulaId/plano-bimestral-infantil",
    name: "gestoesDeAulas.planoBimestralInfantil",
    component: PlanoBimestralInfantil,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas/:id/turma/metricas/:gestao",
    name: "gestoesDeAulas.metricasTurma",
    component: MetricasDaTurma,
  },
  {
    path: "/gestoes-de-aulas/:id/turma/metricas/:gestao/polivalencia",
    name: "gestoesDeAulas.metricasTurma.polivalencia",
    component: MetricasDaTurmaPolivalencia,
  },
  {
    path: "/gestoes-de-aulas/diario",
    name: "gestoesDeAulas.diario",
    component: Diario,
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulaId/plano-mensal/:disciplinaId",
    name: "gestoesDeAulas.planoMensalCreate",
    component: PlanosMensais,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas/:gestaoDeAulaId/plano-mensal-infantil",
    name: "gestoesDeAulas.planoMensalInfantil",
    component: PlanoMensalInfantil,
    props: { editing: false },
  },
];
